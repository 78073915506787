/*******************
 * Configuration of buttons that open modal dialogs.
 *******************/
const ModalButtons = {
	'citation': {
		contentUrl: '/modal/citation',
		title: 'Citations',
		onOpen: function (el, conf) {
			// bootstrap tabs
			var triggerTabList = [].slice.call(el.querySelectorAll('[data-bs-toggle=tab]'));

			triggerTabList.forEach(function (triggerEl) {
				var tabTrigger = new bootstrap.Tab(triggerEl);


				triggerEl.addEventListener('click', function (e) {
					e.preventDefault();
					tabTrigger.show();
				});
			});

			// copy citations
			const copyButtons = el.querySelectorAll('.btn-copy');

			copyButtons.forEach(el => {
				el.onclick = function (e) {
					const copyTarget = document.getElementById(this.dataset.copytarget);
					copyText(copyTarget, this);
				}
			});

			const hideLoader = new CustomEvent('hideLoadingAnimation');
			document.dispatchEvent(hideLoader);
		}
	}
}

export default ModalButtons;