'use strict';
// import {
// 	default as NHMTreeView
// } from './nhm-treeview.js';
import {
	default as ModalButtons
} from './modal-buttons/modal-buttons.js';

import Tags from './bootstrap5-tags/tags-standalone.min.js';
import nhmDataSearch from './nhm-datasearch/nhm-datasearch.js';

/**
 * Callback - executed before Modal is shown
 *
 * @param   Event  e
 *
 * @return  void
 */
function onModalShow(e) {
	const btn = e.relatedTarget;
	const ds = btn.dataset;

	if ('btnid' in ds) {
		const conf = ModalButtons[ds.btnid];
		let url = conf.contentUrl;
		if (ds.param) {
			url += '/' + ds.param;
		}
		const showLoader = new CustomEvent('showLoadingAnimation', { detail: modalBody });
		document.dispatchEvent(showLoader);
		removeChildren(modalBody);
		modalTitle.innerHTML = conf.title;
		//showLoadingAnimation(modalBody);
		fetch(url, {
				cache: 'reload'
			})
			.then(resp => resp.text())
			.then(data => {
				modalBody.innerHTML = data;
				if (conf.onOpen) {
					conf.onOpen(modalBody, conf);
				}
			});
	}
}

/**
 * Copy Text from Element
 *
 * @param   Element target
 *
 * @return  void
 */
function copyText(copyTarget, trigger) {
	window.getSelection().removeAllRanges();
	const range = document.createRange();
	range.selectNode(copyTarget);
	window.getSelection().addRange(range);
	document.execCommand("copy");

	window.getSelection().removeAllRanges();

	trigger.classList.remove('btn-secondary');
	trigger.classList.add('btn-warning');

	setTimeout(() => {
		trigger.classList.add('btn-secondary');
		trigger.classList.remove('btn-warning');
	}, 600);
}
window.copyText = copyText;

/**
 * Show Loading Spinner
 *
 * @param   Element  el  input field
 *
 * @return  void
 */
function showLoadingAnimation(e) {
	const acLoader = document.getElementById('acLoader');
	if (!acLoader) {
		const el = e.detail;
		// create loading spinner Element
		const acLoader = document.createElement('div');
		acLoader.className = 'spinner-border text-secondary';
		acLoader.id = "acLoader";
		acLoader.innerHTML = '<span class="visually-hidden">Loading...</span>';
		// get Coordinates and dimesions for loading spinner
		const bcr = el.getBoundingClientRect();

		const dim = bcr.height - 8;
		const scrollX = window.scrollX;
		const scrollY = window.scrollY;
		const xPos = bcr.x + bcr.width + scrollX - 5 - dim;
		const yPos = bcr.y + scrollY + 4;
		acLoader.setAttribute('style', `position:absolute; left: ${xPos}px; top: ${yPos}px; width: ${dim}px; height: ${dim}px; z-index: 10000`);
		document.body.appendChild(acLoader);

	}
}

document.addEventListener('showLoadingAnimation', showLoadingAnimation);

/**
 * hide Loading Spinner for Autocomplete field
 *
 * @return  void
 */
function hideLoadingAnimation() {
	const acLoader = document.getElementById('acLoader');

	if (acLoader) {
		acLoader.remove();
	}
}
document.addEventListener('hideLoadingAnimation', hideLoadingAnimation);


/**
 * Updates the list of currently selected Entries from treeview
 * Adds data-attributes:
 * - dataid 	dataset id in the database
 * - treeid		jstree element id to be able to delete it
 *
 * @return  void
 */
function updateSelectedEntries(currentEntries, selectedEntries) {
	const selId = selectedEntries.id;

	let lis = document.createDocumentFragment();
	if (currentEntries.size > 0) {
		currentEntries.forEach(function (el, key) {
			const li = document.createElement('li');
			const btn = document.createElement('button');
			btn.className = 'btn btn-sm btn-close';

			btn.dataset.dataid = key;
			btn.dataset.treeid = el.id;

			// force only text when el.text contains tags
			const dummy = document.createElement('div');
			dummy.innerHTML = el.text;

			const text = dummy.firstChild.nodeName === '#text' ? el.text : dummy.firstChild.textContent;

			li.append(btn, text);
			lis.append(li)
		});
	}

	removeChildren(selectedEntries);
	selectedEntries.append(lis);
}

/**
 * Updates filterData to currently selected tree elements
 *
 * @param   {[type]}  data  [data description]
 * @param   {[type]}  id    [id description]
 *
 * @return  {[type]}        [return description]
 */
function updateFilterData(data, id) {
	switch (id) {
		case 'selectedRegions':
			let regionIds = [];
			for (let [key, value] of data) {
				regionIds.push(key);
			}
			filterData.regions = regionIds;
			break;
		case 'selectedTaxa':
			let taxaIds = [];
			for (let [key, value] of data) {
				taxaIds.push(key);
			}
			filterData.taxa = taxaIds;
			break;
		case 'selectedChronology':
			let chronoIds = [];
			for (let [key, value] of data) {
				chronoIds.push(key);
			}
			filterData.chronology = chronoIds;
			break;
		case 'selectedResourceTypes':
			let resIds = [];
			for (let [key, value] of data) {
				resIds.push(key);
			}
			filterData.resourceTypes = resIds;
			break;
		case 'selectedFundingAgencies':
			let fundIds = [];
			for (let [key, value] of data) {
				fundIds.push(key);
			}
			filterData.funding = fundIds;
			break;
		case 'selectedProjects':
			let projIds = [];
			for (let [key, value] of data) {
				projIds.push(key);
			}
			filterData.projects = projIds;
			break;
	}
}

/**
 * Removes all childnodes of the passed element
 *
 * @param   Element  el
 *
 * @return  void
 */
function removeChildren(el) {
	while (el.firstChild) {
		el.removeChild(el.firstChild);
	}
}

/**
 * Clear Search field and current search, set focus to search field
 *
 * @param   Event  e
 *
 * @return  void
 */
function onClearTreeSearch(e) {
	const fld = document.getElementById(e.currentTarget.dataset.clearid);
	if (fld) {
		fld.value = '';
		fld.focus();
		this.jstree('clear_search');
	}
}

/**
 * Deselect entries for a single tree. Must be bound to tree
 *
 * @param   Event  e
 *
 * @return  void
 */
function clearTreeSelection(el) {
	const tree = $(el);
	const selected = tree.jstree('get_selected');

	// cannot use delesect_all -> no node
	selected.forEach(function (el) {
		tree.jstree('deselect_node', el);
	});
}

/**
 * Clear selection of specific tree (this). Call must be bound to tree Object.
 *
 * @param   Event e
 *
 * @return  void
 */
function onClearTreeSelection(e) {
	e.target.classList.add('visually-hidden');
	clearTreeSelection(this);
}

/**
 * Read from global currentSearch and select stored values in tree
 *
 * @param   Object  tree
 * @param   Object  instance
 *
 * @return  void
 */
function setTreeSelection(tree, instance) {
	const treeId = tree.attr('id');

	const filterKey = treeIdToFilter[treeId];

	if (!currentSearch || filterKey in currentSearch.filters === false || !currentSearch.filters[filterKey].length) {
		return;
	}
	// HACK: needed to be able to preselect nodes. Cannot select unopened nodes
	tree.jstree('open_all');

	const selected = currentSearch.filters[filterKey];
	let elems = [];
	for (let i = 0; i < selected.length; i++) {
		const selId = selected[i];
		const elem = tree.jstree('get_node', '[data-entryid="' + selId + '"]');

		if (elem) {
			tree.jstree('select_node', elem);
			elems.push(elem)
		}
	}
	// HACK: close again
	tree.jstree('close_all');

	// Open path to selected Elements
	for (let i = 0; i < elems.length; i++) {
		const element = elems[i];
		instance._open_to(element);

	}
}

/*********************** Init  ************************/
// will be updated and sent when changed
let filterData = {
	regions: [],
	chronology: [],
	taxa: [],
	resourceTypes: [],
	publicationYears: [],
	creators: []
}

// Map Tree Ids to filter keys
const treeIdToFilter = {
	'regionTree': 'regions',
	'chronologyTree': 'chronology',
	'taxonomyTree': 'taxa',
	'fundingTree': 'funding',
	'projectTree': 'projects',
	'resourceTree': 'resourceTypes'
}

const textSearch = document.getElementById('textSearch');
// Info Links open Link in new Tab
const infoLinks = document.querySelectorAll('.info-link');
// hidden field - receives value of currently set filters on submit
const filters = document.getElementById('filters');
// non tree filter values - created by elements other than trees. Must be input fields
const nonTreeFilters = document.querySelectorAll('[data-nontreefilter="true"]');

infoLinks.forEach(function (e) {
	nhmFilter.onclick = function (e) {
		if (e.target.tagName.toLowerCase() == 'svg' || e.target.closest('svg')) {
			const t = e.target;
			if ('infolink' in t.dataset) {
				window.open(t.dataset.infolink, '_blank').focus();
			}
		}
	}
});

// modal:
const modal = document.getElementById('nhmModal');
const modalBody = document.getElementById('nhmModalBody');
const modalTitle = document.getElementById('nhmModalTitle');
nhmModal.addEventListener('show.bs.modal', onModalShow);

/*********************** Treeviews  ************************/
const treeviews = document.querySelectorAll('.nhm-treeview');
const treeConf = {
	"core": {
		"themes": {
			"stripes": true,
			"icons": false
		},
	},
	"checkbox": {
		"three_state": false,
		"cascade": "undetermined",
		"fuzzy": true
	},
	"search": {
		"case_sensitive": false,
		"show_only_matches": true
	},
	"plugins": ["wholerow", "checkbox", "search"]
};

treeviews.forEach(function (el) {
	let currentEntries = new Map();
	let selectedEntries = el.querySelector('.selected-entries');
	const theTree = $(el).find('.jstree').jstree(treeConf).on("changed.jstree", function (e, data) {
		// ready does not have node
		if (data.action === 'ready') {
			return;
		}

		const selNode = data.node;
		const entryId = selNode.data.entryid;
		const selNodeId = selNode.id;
		const txt = selNode.text.trim();
		const btnClearSelected = el.querySelector('.btn-clearselected');

		if (data.action === 'select_node') {
			currentEntries.set(entryId, {
				text: txt,
				id: selNodeId
			});

			// unselect and disable child nodes
			if (selNode.children_d.length > 0) {
				selNode.children_d.forEach(function (elid, i) {
					const currentNode = theTree.jstree('get_node', elid);
					if (theTree.jstree('is_checked', currentNode)) {
						theTree.jstree('uncheck_node', currentNode);
					}
					theTree.jstree('disable_node', currentNode);
				});
				// because of unchecking children selNode is set to undetermined and is unchecked. we have to check it again
				theTree.jstree('check_node', selNode);
			}
		} else if (data.action === 'deselect_node') {
			currentEntries.delete(entryId);
			// unselect and disable child nodes
			if (selNode.children_d.length > 0) {
				selNode.children_d.forEach(function (elid, i) {
					const currentNode = theTree.jstree('get_node', elid);
					theTree.jstree('enable_node', currentNode);
				});
			}
		}

		updateSelectedEntries(currentEntries, selectedEntries);
		updateFilterData(currentEntries, selectedEntries.id);

		// clear search,
		const fld = this.closest('.nhm-treeview').querySelector('.tree-search');
		if (fld) {
			fld.value = '';
			fld.focus();
			theTree.jstree('clear_search');
		}

		if (selectedEntries.getElementsByTagName('li').length > 0) {
			btnClearSelected.classList.remove('visually-hidden');
		} else {
			btnClearSelected.classList.add('visually-hidden');
		}
	});

	// text search in tree
	const treeSearch = el.querySelector('.tree-search');
	treeSearch.addEventListener('keyup', function (e) {
		const val = this.value;
		if (val.length < 2) {
			theTree.jstree('clear_search');
			return;
		}
		// Delay search - better performance
		clearTimeout(window.keyupDelay);
		window.keyupDelay = setTimeout(function () {
			theTree.jstree('search', val);
		}, 250);
	});

	// Preselect Ids in current search
	theTree.on('ready.jstree', function (e, data) {
		setTreeSelection($(this), data.instance);
	});

	// clear search Buttons
	const btnClearSearch = el.querySelector('.btn-clearsearch');
	if (btnClearSearch) {
		btnClearSearch.addEventListener('pointerdown', onClearTreeSearch.bind(theTree));
	}

	const btnClearSelected = el.querySelector('.btn-clearselected');
	if (btnClearSelected) {
		btnClearSelected.addEventListener('pointerdown', onClearTreeSelection.bind(theTree));
	}

	//remove selectedEntries from tree when remove button is clicked
	selectedEntries.addEventListener('pointerdown', function (e) {
		const t = e.target;

		if (t.classList.contains('btn-close')) {
			const el = theTree.jstree('get_node', t.dataset.treeid);

			if (el) {
				theTree.jstree('deselect_node', el);
			}
		}
	});
});

/**
 * Update selections of all non tree elements. Each component must
 * data-nontreefilter="true" and have a hidden field.
 * The hidden fields id is stored in data-id.
 *
 * @return  void
 */
function updateNonTreeFilters() {
	nonTreeFilters.forEach(el => {
		const id = el.dataset.id;

		if (el.value) {
			const data = el.value.split(',');
			// convert to int
			filterData[id] = data.map(function (x) {
				return parseInt(x, 10);
			});
		} else {
			filterData[id] = [];
		}
	});
}

/**
 * When tags are loaded/selected/deselected write current selection to selectedVals
 *
 * @param   Element  tag
 *
 * @return  void
 */
function updateSelectedTags(tag)
{
	let selectedVals = [];
	allTags.forEach(function (el) {
		if (el.classList.contains('active')) {
			selectedVals.push(el.dataset.tagid);
		}
	});

	searchTags.value = selectedVals.join(',');
}

/**
 * Write currently selected Checkboxgroup values to its associated hidden input
 *
 * @param   List checkboxes
 * @param   element  input
 *
 * @return  void
 */
function updateMyCheckboxes(checkboxes, input) {
	input.value = '';
	let values = [];

	// get values of all selected boxes
	checkboxes.forEach(c => {
		if (c.checked) {
			values.push(c.value);
		}
	});
	input.value = values.join(',');
}

function clearTags() {
	allTags.forEach(el => {
		if (el.classList.contains('active')) {
			el.classList.remove('active');
		}
	});
	updateSelectedTags();
}
/*********************** Search Form *******************************/
const searchForm = document.getElementById('searchForm');

searchForm.addEventListener('submit', function (e) {
	updateNonTreeFilters();
	// set current filter data
	filters.value = JSON.stringify(filterData);
});

// Reset all
// const resetAll = document.getElementById('resetAll');
// resetAll.addEventListener('click', function (e) {
// 	textSearch.value = '';
// 	treeviews.forEach(function (tv) {
// 		const tree = tv.querySelector('.jstree');
// 		clearTreeSelection(tree)
// 	});

// 	nonTreeFilters.forEach(function (el) {
// 		el.value = '';
// 	});

// 	// nhmSearch Reset
// 	nhmDataSearchFields.forEach(function (el) {
// 		nhmDataSearch.reset(el);
// 	});

// 	// reset other filters
// 	chbGroups.forEach(group => {
// 		const myCheckboxes = group.querySelectorAll('input[type="checkbox"]');
// 		myCheckboxes.forEach(chb => chb.checked = false);
// 		const myInput = document.getElementById(group.dataset.inputid);
// 		myInput.value = '';
// 	});

// 	clearTags();
// });

/*********************** Bootstrap Accordion states  ************************/
const filterAccordion = document.getElementById('nhmFilter');
let openPanels = {};
let storedItems = localStorage.getItem('nhmFilter');

if (storedItems) {
	openPanels = JSON.parse(storedItems);
}

for (const id in openPanels) {
	if (openPanels[id] === true) {
		const el = document.getElementById(id);
		if (el) {
			const c = new bootstrap.Collapse(el).show();
		}
	}
}

filterAccordion.addEventListener('show.bs.collapse', function (e) {
	const id = e.target.id;

	openPanels[id] = true;
	localStorage.setItem('nhmFilter', JSON.stringify(openPanels));
});

filterAccordion.addEventListener('hide.bs.collapse', function (e) {
	const id = e.target.id;
	openPanels[id] = false;
	localStorage.setItem('nhmFilter', JSON.stringify(openPanels));
});

/*********************** Tags  ************************/
const tagsList = document.getElementById('tagsList');
const allTags = tagsList.querySelectorAll('.nhm-tag');
// input
const searchTags = document.getElementById('searchTags');

tagsList.addEventListener('pointerdown', function (e) {
	const clicked = e.target.closest('.nhm-tag');
	if (clicked) {
		clicked.classList.toggle('active');
		updateSelectedTags();
	}
});

updateSelectedTags();



/*********************** Checkboxgroups  ************************/
const chbGroups = document.querySelectorAll('[data-filtercheckboxgroup="true"]');

chbGroups.forEach(group => {
	const myCheckboxes = group.querySelectorAll('input[type="checkbox"]');
	const myInput = document.getElementById(group.dataset.inputid);



	updateMyCheckboxes(myCheckboxes, myInput);
	// change handler on each checkbox
	myCheckboxes.forEach(chb => {
		chb.onchange = function (e) {
			updateMyCheckboxes(myCheckboxes, myInput);
		}
	});
});

/*********************** Search UI Component  ************************/
const nhmDataSearchFields = document.querySelectorAll('.nhm-data-search');
nhmDataSearchFields.forEach(el => nhmDataSearch.init(el, filterData));

/*********************** Read more *******************************/
const readMore = document.querySelectorAll('.show-more');
const readMoreBtn = document.querySelectorAll('.btn-show-more');

readMore.forEach(function (el) {
	if (el.clientHeight >= el.scrollHeight) {
		el.style.setProperty('--gradient-display', 'none');
		const btn = document.querySelector('[data-moreid="' + el.id + '"]');
		btn.remove();
		return false;
	}
	el.dataset.isopen = false;
});

readMoreBtn.forEach(function (el) {
	el.onclick = function (e) {
		const moreId = this.dataset.moreid;
		const altText = this.dataset.alttext;
		this.dataset.alttext = this.innerText;
		this.innerText = altText;

		if (moreId) {
			const more = document.getElementById(moreId);
			if (more.dataset.isopen == 'false') {
				more.style.maxHeight = 'calc(' + more.scrollHeight + 'px + 7em)';
				more.dataset.isopen = 'true';
			} else {
				more.style.removeProperty('max-height');
				more.dataset.isopen = false;
			}
		}
	}
});